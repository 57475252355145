import { render, staticRenderFns } from "./myvolunteer.vue?vue&type=template&id=2439516e&scoped=true"
import script from "./myvolunteer.vue?vue&type=script&lang=js"
export * from "./myvolunteer.vue?vue&type=script&lang=js"
import style0 from "./myvolunteer.vue?vue&type=style&index=0&id=2439516e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2439516e",
  null
  
)

export default component.exports