<template>
  <div class="rightbox">
    <div v-for="(item, index) in tabeldata" :key="index" class="cardiv"
      @click="godetail(item)">
      <el-card shadow="hover">
        <div class="smalldiv">
          <div class="img">
            <img src="../../assets/personalCenter/volunteer.png" style="width: 40px; margin-left: 15px; margin-top: 15px"
              alt="" />
          </div>
          <div class="text">
            <div style="font-size: 16px; color: #222">
              {{ item.analogName }}
              <el-tag type="info" size="small">{{
                item.fillType == 1 ? "个性化方案" : "智能方案"
              }}</el-tag>
            </div>
            <div style="font-size: 12px; color: #999">
              <span>{{ item.totalScore + "分" }}</span>
              <span v-if="item.disciplineType == 0">不分文理</span>
              <span v-if="item.disciplineType == 1">理科</span>
              <span v-if="item.disciplineType == 2">文科</span>
              <span v-if="item.disciplineType == 3">物理类</span>
              <span v-if="item.disciplineType == 4">历史类</span>
              <span>{{ item.admitBatch }}</span>
            </div>
          </div>
          <div class="del">
            <el-button type="text" style="color: #f56c6c" @click.stop="deletezy(item.aspirationAnalogId)">删除</el-button>
            <span style="
                  font-size: 28px;
                  color: #e5e5e5;
                  display: inline-block;
                  margin-top: 21px;
                "><i class="el-icon-arrow-right"></i></span>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {
  deletezy, //删除志愿
  getPageByStudentId, //志愿列表
} from "@/api/thirdPage";
export default {
  data() {
    return {
      studentId: "",
      tabeldata: [], //志愿列表
    };
  },
  mounted() {
    console.log(JSON.parse(sessionStorage.getItem('student')).studentId, 'studentId');
    this.studentId = JSON.parse(sessionStorage.getItem('student')).studentId || this.$route.query.studentId;
    this.getPageByStudentId();
  },
  methods: {
    deletezy(aspirationAnalogId) {
      deletezy({ aspirationAnalogId: aspirationAnalogId }).then((res) => {
        if (res.code == 1) {
          this.getPageByStudentId();
        }
      });
    },
    godetail(item) {
      this.$store.commit("setVoPa", item.voPa);
      let routeData = this.$router.resolve({
        path: "/planDetail",
        query: {
          aspirationAnalogId: item.aspirationAnalogId,
          intelligent: item.fillType,
          voPa: item.voPa
        },
      });
      window.open(routeData.href, "_blank");
    },
    getPageByStudentId() {
      getPageByStudentId({
        size: 10,
        current: 1,
        studentId: this.studentId,
      }).then((res) => {
        if (res.code == 1) {
          let data = res.data || {};
          this.tabeldata = data.records || [];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.rightbox {
  width: 950px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  /deep/.el-card {
    border-radius: 10px;
  }

  /deep/.el-card__body {
    padding: 0px;
  }

  .cardiv {
    width: 390px;
    height: 150px;
    margin: 10px 20px;

    .smalldiv {
      display: flex;
      justify-content: flex-start;
      width: 390px;
      height: 130px;

      .img {
        width: 70px;
        height: 70px;
        border-radius: 35px;
        background-color: rgb(202, 248, 228);
        margin: 30px 15px;
      }

      .text {
        margin: 30px 15px;
        height: 70px;
        width: 160px;

        div {
          line-height: 35px;
        }
      }

      .del {
        margin: 30px 15px;
        height: 70px;

        /deep/.el-button {
          vertical-align: top;
          padding: 7px 0px;
          margin-top: 21px;
        }
      }
    }
  }
}
</style>